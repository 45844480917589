import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const FeatureCard = ({ title, description, icon }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="text-4xl mb-4 text-blue-600">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
};

function LandingPage() {
  return (
    <div className="text-center bg-gradient-to-b from-gray-900 to-gray-800 text-white min-h-screen flex flex-col">
      <section className="py-20 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-5xl sm:text-6xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-600"
          >
            ComplyWise: AI-Powered Pharmaceutical Compliance Management
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl sm:text-2xl mb-8 text-gray-300"
          >
            Revolutionize your pharmaceutical compliance with proactive AI-driven regulatory updates and automation
          </motion.p>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link
              to="/schedule-demo"
              className="bg-gradient-to-r from-blue-500 to-green-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-green-700 transition duration-300 shadow-lg inline-block"
            >
              Schedule a Demo
            </Link>
          </motion.div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="w-96 h-96 bg-blue-500 rounded-full filter blur-3xl opacity-20 animate-blob"></div>
          <div className="w-96 h-96 bg-green-500 rounded-full filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
          <div className="w-96 h-96 bg-yellow-500 rounded-full filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
        </div>
      </section>

      <section className="py-16 bg-gray-900 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-bold mb-12 text-white"
          >
            Key Features of ComplyWise
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              title="Dynamic Regulatory Adaptation"
              description="AI-driven monitoring of global regulatory updates with automated impact assessment and SOP update recommendations."
              icon="🔄"
            />
            <FeatureCard
              title="Proactive Compliance Monitoring"
              description="Real-time dashboard with customizable alerts for instant detection of compliance issues across all facilities."
              icon="🔍"
            />
            <FeatureCard
              title="AI-Powered Quality Analysis"
              description="Predictive analysis of manufacturing processes to detect subtle variations and prevent compliance breaches."
              icon="🧠"
            />
            <FeatureCard
              title="Automated Audit Preparation"
              description="AI-generated, tailored pre-audit checklists and simulated audit scenarios for regulatory bodies like CDSCO."
              icon="📋"
            />
            <FeatureCard
              title="Smart Document Management"
              description="AI-powered search and retrieval of relevant documents with automated cross-referencing and version control."
              icon="📄"
            />
            <FeatureCard
              title="Continuous Compliance Monitoring"
              description="Automatic scanning of your entire document repository against the latest regulatory requirements."
              icon="🔎"
            />
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-bold mb-12 text-white"
          >
            Why Choose ComplyWise?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard
              title="Proactive Regulatory Compliance"
              description="Stay ahead of over 1000 compliance regulations and 3500+ annual updates with real-time AI monitoring."
              icon="🚀"
            />
            <FeatureCard
              title="Enhanced Efficiency and Quality"
              description="Reduce reaction time to compliance issues by 50% and decrease batch rejections by up to 50%."
              icon="📈"
            />
            <FeatureCard
              title="Comprehensive Regulatory Coverage"
              description="Tailored for Indian pharma companies, covering CDSCO, US FDA, EMA, WHO GMP, and more."
              icon="🌐"
            />
            <FeatureCard
              title="100% Data Security and Control"
              description="Fully encrypted data with granular permissions, ensuring complete control over your sensitive information."
              icon="🔒"
            />
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-900 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-bold mb-12 text-white"
          >
            How ComplyWise Works
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard
              title="AI-Powered Scanning"
              description="Our advanced AI continuously scans global regulatory updates, your internal documents, and industry best practices."
              icon="🔬"
            />
            <FeatureCard
              title="Intelligent Analysis"
              description="ComplyWise analyzes the data to identify compliance gaps, potential risks, and areas for improvement in your processes."
              icon="📊"
            />
            <FeatureCard
              title="Automated Recommendations"
              description="Based on the analysis, ComplyWise generates tailored recommendations and action items to enhance your compliance."
              icon="💡"
            />
            <FeatureCard
              title="Continuous Monitoring"
              description="Our system provides real-time alerts and updates, ensuring you're always ahead of regulatory changes and potential compliance issues."
              icon="🔔"
            />
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-bold mb-12 text-white"
          >
            Data Security: Our Top Priority
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard
              title="End-to-End Encryption"
              description="All your data is encrypted at rest and in transit, ensuring maximum protection against unauthorized access."
              icon="🔐"
            />
            <FeatureCard
              title="Granular Access Control"
              description="Set precise permissions for each user, ensuring data access is strictly on a need-to-know basis."
              icon="🔑"
            />
            <FeatureCard
              title="Regular Security Audits"
              description="We conduct frequent security assessments and penetration testing to maintain the highest standards of data protection."
              icon="🛡️"
            />
            <FeatureCard
              title="Compliance with Global Standards"
              description="ComplyWise adheres to GDPR, HIPAA, and other international data protection regulations to safeguard your sensitive information."
              icon="🌐"
            />
          </div>
        </div>
      </section>

      <section className="py-16 relative overflow-hidden">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl font-bold mb-8 text-white"
        >
          Ready to Transform Your Pharmaceutical Compliance?
        </motion.h2>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Link to="/schedule-demo" className="inline-block bg-gradient-to-r from-blue-500 to-green-600 text-white py-4 px-10 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-green-700 transition duration-300 shadow-lg">
            Schedule a Demo
          </Link>
        </motion.div>
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
          <div className="absolute top-3/4 left-3/4 w-64 h-64 bg-green-500 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute top-1/2 left-1/2 w-64 h-64 bg-yellow-500 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
